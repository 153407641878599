import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const SuccessPage = () => {
  return (
    <Layout>
      <SEO title="Bericht verzonden - Uytterhaegen Jan BV" pathname="/" />
      <div className="main-hero">
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Bericht is verzonden!</h2>
            <p>
              Bedankt voor je bericht, we contacteren je zo snel mogelijk terug.
            </p>
            <Link to="/" aria-label="Home">
              Terug naar de website.
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessPage
